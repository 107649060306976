import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import dataV from "@jiaminghi/data-view"
// 引入全局css
import "./assets/scss/style.scss"
// 按需引入vue-awesome图标
import Icon from "vue-awesome/components/Icon"
import "vue-awesome/icons/chart-bar.js"
import "vue-awesome/icons/chart-area.js"
import "vue-awesome/icons/chart-pie.js"
import "vue-awesome/icons/chart-line.js"
import "vue-awesome/icons/align-left.js"

//引入echart
//4.x 引用方式
import echarts from "echarts"
//5.x 引用方式为按需引用
//希望使用5.x版本的话,需要在package.json中更新版本号,并切换引用方式
//import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false

import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"

Vue.use(ElementUI)

import axios from "axios"

// 创建一个 axios 实例
const api = axios.create()

// 把 axios 实例挂在到 Vue 实例上
Vue.prototype.$http = api

// import VueAwesomeSwiper from 'vue-awesome-swiper'

// import style (>= Swiper 6.x)
// import 'swiper/swiper-bundle.css'

// import style (<= Swiper 5.x)
// import 'swiper/css/swiper.css'

// Vue.use(VueAwesomeSwiper)
import VueAwesomeSwiper from "vue-awesome-swiper"
import "swiper/css/swiper.css"
Vue.use(VueAwesomeSwiper)
// 全局注册
Vue.component("icon", Icon)
Vue.use(dataV)

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app")
