import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

const routes = [
  {
    path: "/many",
    name: "many",
    component: () => import("../views/manyjoin/index.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("../views/biglower.vue"),
  },
  {
    path: "/",
    name: "index",
    component: () => import("../views/index.vue"),
  },
  {
    path: "/bg",
    name: "bg",
    component: () => import("../views/biglow/beijing.vue"),
  },
  {
    path: "/degree",
    name: "dg",
    component: () => import("../views/biglow/degree.vue"),
  },
  {
    path: "/chunan",
    name: "chunan",
    component: () => import("../views/chunan/index.vue"),
  },
  {
    path: "/yanqing",
    name: "yanqing",
    component: () => import("../views/yanqing/index.vue"),
  },
  {
    path: "/huairou",
    name: "huairou",
    component: () => import("../views/huairou/index.vue"),
  },
  {
    path: "/village",
    name: "village",
    component: () => import("../views/village/cun_detail.vue"),
  },
  {
    path: "/town",
    name: "town",
    component: () => import("../views/town/town_detail.vue"),
  },
]
const router = new VueRouter({
  mode: "history",
  routes,
})

export default router
